import React from "react";
var __jsx = React.createElement;
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

var Navbar = function Navbar(_ref) {
  var locale = _ref.locale,
      translate = _ref.translate;
  var router = useRouter();

  var _useState = useState(false),
      scrolled = _useState[0],
      setScrolled = _useState[1];

  var changeLocale = function changeLocale(locale) {
    localStorage.setItem("selectedLocale", locale);
    router.push({
      route: router.pathname,
      query: router.query
    }, router.asPath, {
      locale: locale
    }).then(function () {
      // router.reload();
      window.href = router.asPath + "/" + locale;
    });
  };

  useEffect(function () {
    var onScroll = function onScroll() {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return function () {
      return window.removeEventListener("scroll", onScroll);
    };
  }, []);
  return __jsx("nav", {
    className: "navbar navbar-expand-lg sticky-top d-flex align-items-center justify-content-center ".concat(scrolled ? "scrolled" : "")
  }, __jsx("div", {
    className: "mainContainer d-lg-flex align-items-center justify-content-between"
  }, __jsx(Link, {
    className: "navbar-brand",
    href: "/",
    passHref: true
  }, __jsx("div", {
    className: "site-logo d-flex align-items-center fs-3"
  }, __jsx("img", {
    src: "/assets/logo.png",
    className: "img-fluid",
    alt: "Img",
    style: {
      height: "auto",
      width: "60px"
    }
  }), translate("site-name"))), __jsx("button", {
    className: locale == "ar" ? "navbar-toggler navbar-toggler-rtl" : "navbar-toggler navbar-toggler-ltr",
    type: "button",
    "data-bs-toggle": "collapse",
    "data-bs-target": "#navbarCollapse",
    "aria-controls": "navbarCollapse",
    "aria-expanded": "false",
    "aria-label": "Toggle navigation"
  }, __jsx("span", {
    className: "navbar-toggler-icon"
  }, __jsx("i", {
    className: "fa-solid fa-bars"
  }))), __jsx("div", {
    className: "collapse navbar-collapse d-lg-flex justify-content-between ",
    id: "navbarCollapse"
  }, __jsx("ul", {
    className: "navbar-nav d-lg-flex align-items-center ms-5"
  }, __jsx("li", {
    className: "nav-item"
  }, __jsx(Link, {
    href: "/",
    passHref: true
  }, translate("home"))), __jsx("li", {
    className: "nav-item"
  }, __jsx(Link, {
    href: "/#why-appscoper",
    passHref: true
  }, translate("why_appscoper?"))), __jsx("li", {
    className: "nav-item"
  }, __jsx(Link, {
    href: "/#features",
    passHref: true
  }, translate("features"))), __jsx("li", {
    className: "nav-item"
  }, __jsx(Link, {
    href: "/#how-it-works",
    passHref: true
  }, translate("how-works"))), __jsx("li", {
    className: "nav-item"
  }, __jsx(Link, {
    href: "/#pricing",
    passHref: true
  }, translate("pricing"))), __jsx("li", {
    className: "nav-item"
  }, __jsx(Link, {
    href: "/#faqs",
    passHref: true
  }, translate("faq"))), __jsx("li", {
    className: "nav-item"
  }, __jsx(Link, {
    href: "/#contact",
    passHref: true
  }, translate("contact")))), __jsx("div", {
    className: "d-lg-flex justify-content-end "
  }, __jsx("ul", {
    className: "navbar-nav d-lg-flex align-items-center"
  }, __jsx("li", {
    className: "nav-item"
  }, __jsx("div", {
    className: "dropdown"
  }, __jsx("div", {
    "data-bs-toggle": "dropdown",
    className: "dropLang "
  }, __jsx("span", {
    className: "d-flex justify-content-between align-items-center"
  }, __jsx("i", {
    className: "fa-solid fa-globe fs-3"
  }), " ")), __jsx("div", {
    className: "dropdown-menu collapse"
  }, __jsx("ul", null, __jsx("li", {
    onClick: function onClick() {
      return changeLocale("en");
    },
    className: locale === "en" ? "word-selected" : "word"
  }, translate("english")), __jsx("li", {
    onClick: function onClick() {
      return changeLocale("ar");
    },
    className: locale === "ar" ? "word-selected mt-2" : "word mt-2"
  }, translate("arabic")))))), __jsx("li", {
    className: "nav-item"
  }, __jsx("i", {
    className: "fa-solid fa-user me-1"
  }), " ", __jsx(Link, {
    href: "https://me.appscoper.com/#/login",
    passHref: true
  }, translate("login"))))))));
};

export default Navbar;