import _objectWithoutProperties from "/opt/bitnami/apache/htdocs/Appscoper-website/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["locales", "defaultLocale"];
import { useRouter } from "next/router";
import translate from "../lang/main";
import { httpJson, httpForm } from "../config/http";
var ISSERVER = typeof window === "undefined";
var lang;

if (!ISSERVER) {
  lang = localStorage.getItem("selectedLocale");
}

export var useTranslation = function useTranslation() {
  var _useRouter = useRouter(),
      _useRouter$locales = _useRouter.locales,
      locales = _useRouter$locales === void 0 ? [] : _useRouter$locales,
      defaultLocale = _useRouter.defaultLocale,
      nextRouter = _objectWithoutProperties(_useRouter, _excluded);

  var locale = locales.includes(nextRouter.locale || "") ? nextRouter.locale : lang;
  httpJson.defaults.headers["lang"] = locale;
  httpForm.defaults.headers["lang"] = locale;
  return {
    t: function t(text) {
      var translation = translate[locale][text];
      return Boolean(translation) ? translation : text;
    },
    locale: function locale(objLocales) {
      var keys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var nullable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var avalibleLocales = keys ? Object.keys(objLocales) : objLocales.map(function (obj) {
        return obj.locale;
      });
      var usedLocale = avalibleLocales.includes(nextRouter.locale) ? nextRouter.locale : nullable ? null : avalibleLocales.includes(defaultLocale) ? defaultLocale : avalibleLocales[0];
      return usedLocale !== null && usedLocale !== void 0 ? usedLocale : null;
    },
    selectedLocale: locale
  };
};