import _defineProperty from "/opt/bitnami/apache/htdocs/Appscoper-website/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
var __jsx = React.createElement;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useState } from "react";
import Link from "next/link";
import { useTranslation } from "../util/useTranslation";
import { toast } from "react-toastify";
import { news_subscribe } from "../pages/api/contact";

var Footer = function Footer() {
  var _useTranslation = useTranslation(),
      translate = _useTranslation.t;

  var _useState = useState({
    email: ""
  }),
      formData = _useState[0],
      setFormData = _useState[1];

  var _useState2 = useState({}),
      errors = _useState2[0],
      setErrors = _useState2[1];

  var validateForm = function validateForm() {
    var errors = {};

    if (!formData.email) {
      errors.email = translate("email_required");
    } else if (!isValidEmail(formData.email)) {
      errors.email = translate("invalid_email");
    } else {
      errors.email = "";
    }

    setErrors(errors);
    return Object.values(errors).every(function (error) {
      return !error;
    });
  };

  var isValidEmail = function isValidEmail(email) {
    var emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  };

  var submit = function submit(e) {
    e.preventDefault();

    if (validateForm()) {
      news_subscribe(JSON.stringify(formData)).then(function () {
        toast.success(translate("subscribe_successfully"));
        setFormData({
          email: ""
        });
      })["catch"](function (error) {
        toast.error(error.response.data.message);
        console.log(error);
      });
    }
  };

  var handleInputChange = function handleInputChange(e) {
    setFormData(_objectSpread(_objectSpread({}, formData), {}, _defineProperty({}, e.target.name, e.target.value))); // Clear the corresponding error when typing

    setErrors(_objectSpread(_objectSpread({}, errors), {}, _defineProperty({}, e.target.name, "")));
  };

  return __jsx(React.Fragment, null, __jsx("footer", {
    className: "d-flex align-items-center justify-content-center",
    style: {
      fontSize: "14px"
    }
  }, __jsx("div", {
    className: "shape-top"
  }), __jsx("div", {
    className: "mainContainer"
  }, __jsx("div", {
    className: "top-footer "
  }, __jsx("div", {
    className: "row"
  }, __jsx("div", {
    className: "col-md-6"
  }, __jsx("div", {
    className: "site-logo d-flex align-items-center"
  }, __jsx("img", {
    src: "/assets/logo.png",
    className: "img-fluid",
    alt: "Img",
    style: {
      height: 'auto',
      width: '60px'
    }
  }), "APPSCOPER"), __jsx("p", null, translate("footer_title")), __jsx("div", {
    className: "footer-social-links"
  }, __jsx(Link, {
    href: "https://www.facebook.com/profile.php?id=61551679927758",
    passHref: true
  }, __jsx("a", {
    href: "#"
  }, __jsx("i", {
    className: "fa-brands fa-facebook-f"
  }))), __jsx("a", {
    href: "#"
  }, __jsx("i", {
    className: "fa-brands fa-linkedin"
  })))), __jsx("div", {
    className: "col-md-2"
  }, __jsx("h4", {
    className: "footer-title"
  }, translate("useful_links")), __jsx("ul", {
    className: "footer-links"
  }, __jsx("li", null, __jsx("i", {
    className: "fa-solid fa-angles-right text-white"
  }), __jsx(Link, {
    href: "/",
    passHref: true
  }, translate("home"))), __jsx("li", null, __jsx("i", {
    className: "fa-solid fa-angles-right text-white"
  }), __jsx(Link, {
    href: "/#contact",
    passHref: true
  }, translate("contact"))), __jsx("li", null, __jsx("i", {
    className: "fa-solid fa-angles-right text-white"
  }), __jsx(Link, {
    href: "/#faqs",
    passHref: true
  }, translate("faq"))), __jsx("li", null, __jsx("i", {
    className: "fa-solid fa-angles-right text-white"
  }), __jsx(Link, {
    href: "/privacy-policy",
    passHref: true
  }, translate("privacy"))))), __jsx("div", {
    className: "col-md-4"
  }, __jsx("h4", {
    className: "footer-title"
  }, translate("newsletter")), __jsx("p", null, translate("newsletter_desc")), __jsx("form", {
    className: "newsletter-form",
    onSubmit: submit
  }, __jsx("input", {
    name: "email",
    type: "email",
    value: formData.email,
    onChange: handleInputChange,
    placeholder: translate("email_placeholder")
  }), errors.email && __jsx("div", {
    className: "error-message"
  }, errors.email), __jsx("button", {
    className: "btn theme-btn",
    type: "submit"
  }, translate("subscribe")))))), __jsx("div", {
    className: "copyrights"
  }, __jsx("p", null, "Copyrights \xA9", __jsx("a", {
    href: "#"
  }, " Appscopper "), "2023. ", __jsx("br", null), "version: 1.2.1")))));
};

export default Footer;