import React from "react";
var __jsx = React.createElement;
import Head from "next/head";
import { useRouter } from "next/router";
import { useTranslation } from "../util/useTranslation";
import Navbar from "./Navbar";
import Footer from "./Footer";

var Layout = function Layout(_ref) {
  var title = _ref.title,
      children = _ref.children,
      navTitle = _ref.navTitle;
  var router = useRouter();
  var locale = router.locale;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return __jsx("div", {
    className: locale == "ar" ? "arabicContainer" : ""
  }, __jsx(Head, null, __jsx("title", null, title ? "".concat(t(title), "  | ").concat(t("site-name")) : t("site-name")), __jsx("link", {
    rel: "icon",
    href: "/favicon.ico"
  })), __jsx(Navbar, {
    locale: locale,
    translate: t
  }), __jsx("div", {
    style: {
      minHeight: "80vh"
    },
    id: "header-area"
  }, children), __jsx(Footer, null));
};

export default Layout;